import { RefuseOrderModal } from '@components/Modals/ifood'
import { useWhatsAppBot } from '@hooks/useWhatsAppBot'
import i18n from 'i18n'
import { DateTime } from 'luxon'
import { useSession } from 'next-auth/react'
import React, { FormHTMLAttributes, useContext, useRef, useState } from 'react'
import { Button, ButtonProps, Spinner } from 'react-bootstrap'
import { GiFullMotorcycleHelmet } from 'react-icons/gi'
import { api } from 'src/lib/axios'
import { AppContext } from '../../context/app.ctx'
import { CartsContext } from '../../context/cart.ctx'
import useLocalStorage from '../../hooks/useLocalStorage'
import Cart from '../../types/cart'
import { ProfileFormPayment, ProfileOptions } from '../../types/profile'
import { encodeTextURL } from '../../utils/wm-functions'

type SendStatusMessageFormProps = {
  button: { name: string; props?: ButtonProps }
  cart: Cart
  newStatus?: null | 'production' | 'transport' | 'motoboy' | 'canceled'
  profileOptions?: ProfileOptions
} & FormHTMLAttributes<HTMLFormElement>

interface Reason {
  cancellationCode: string
  reason?: string
}

export const SendStatusMessageForm = ({
  button,
  cart,
  newStatus,
  profileOptions,
  ...rest
}: SendStatusMessageFormProps) => {
  const { data: session } = useSession()
  const {
    door,
    lastRequestDate,
    profile,
    handleShowToast,
    setRequestsToPackage,
    requestsToPackage,
    possibleMobile,
  } = useContext(AppContext)
  const { setCarts, carts, setCart } = useContext(CartsContext)
  const [showReasons, setShowReasons] = useState(false)
  const [reasons, setReasons] = useState<Reason[]>([])
  const { setNewPlacedOrders, motoboys } = useContext(CartsContext)

  const [message, setMessage] = useState(
    (profileOptions ?? profile.options).placeholders.clientText
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [closeTabs, setCloseTabs] = useLocalStorage(
    '@wmstatus:closeTabs',
    localStorage.getItem('@wmstatus:closeTabs') ?? false
  )

  const { sendMessage, onMessageSend } = useWhatsAppBot()

  const handleChangeStatus = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (cart.origin === 'ifood' && newStatus === 'canceled' && cart.orderId) {
      await cancellationReasons(cart.orderId)
    } else {
      handleSendMessage(e)
    }
  }

  const cancellationReasons = async (orderId: string) => {
    try {
      const { data } = await api.get(
        `/dashboard/ifood/order/${orderId}/cancellationReasons`
      )

      data.forEach((reasons: any) => {
        if (reasons) {
          reasons.cancellationCode = reasons.cancelCodeId
          reasons.reason = reasons.description

          delete reasons.cancelCodeId
          delete reasons.description
        }
      })
      setReasons(data)
      setShowReasons(true)
    } catch (error) {
      console.error('erro ao cancelar o pedido no ifood', error)
    }
  }

  const buttonRef = useRef<HTMLButtonElement>(null)
  const handleSendMessage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const currMotoboy = motoboys.find((moto) => moto.id === cart.motoboyId)
    /**
     * Calcula o valor da taxa ou desconto do pedido
     * @param {string} taxOrDisc - 'taxa' ou 'desconto'
     * @param {string} type - 'fixo' ou 'porcentagem'
     * @param {number} value - valor da taxa ou desconto
     * @param {number} price - preço para aplicar a taxa ou o desconto
     * @returns {number} valor calculado da taxa ou desconto
     */
    const calcTaxOrDisc = (
      addon: ProfileFormPayment['addon'],
      price: number
    ) => {
      if (!addon) {
        return 0
      }
      let { type, value, valueType } = addon
      value = Number(value)
      if (value <= 0) {
        return 0
      }
      switch (valueType) {
        case 'fee':
          if (type === 'fixed') {
            return value
          } else {
            return (value / 100) * price
          }
        case 'discount':
          if (type === 'fixed') {
            return 0 - value
          } else {
            return 0 - (value / 100) * price
          }
        default:
          return 0
      }
    }

    const fullAdress = encodeURIComponent(
      cart.address && cart.addressId
        ? `${cart.address.street ?? ''}, ${cart.address.number ?? ''} - ${cart.address.neighborhood ?? ''}, ${cart.address.city ?? ''} - ${cart.address.uf ?? ''}, ${cart.address.zipcode ?? ''}`
        : ''
    )
    const change: number = Number(
      (
        Number(cart.formsPayment.find((pay) => pay.label === 'Dinheiro')?.change) ?? 0
      ).toFixed(2)
    )
    const { addon } = cart?.formsPayment[0] ?? {}
    const taxValue: number = calcTaxOrDisc(addon, Number(cart.total))
    const cashbackValue: number = cart.formsPayment.some((pay) => pay.label === 'Cashback') ? Number(cart.formsPayment.find((pay) => pay.label === 'Cashback')?.value) : 0
    let coupomValue: number = 0
    if (cart.cupom) {
      const { type, value } = cart.cupom;
      if (type === 'value') {
        coupomValue = Number(value)
      }
      if (type === 'percent') {
        coupomValue = (Number(cart.total) * (Number(value) / 100))
      }
    }
    const finalPrice: number = Number(
      (
        Number(cart.total) +
        Number(cart.taxDelivery) +
        Number(taxValue) -
        Number(cashbackValue) -
        Number(coupomValue)
      ).toFixed(2)
    )
    const motoboyDefaultMessage =
      cart.address && cart.addressId
        ? `*${profile.name}*

Olá *${currMotoboy?.name}* o pedido já está pronto para ser retirado! 
Aqui está o contato do cliente *${cart.client.name}*, caso precise: 
+${i18n.t('ddi')}${cart.client.whatsapp}

*Código do pedido:* wm${cart.code}-${cart.type}

${cart.itens
          .map((item) => {
            return `${item.quantity}x - ${item.name} (R$${(Number(item.quantity) * Number(item.details.value)).toFixed(2)})
(OBS: ${item.obs !== '' ? item.obs : '_Não informado_'})\n
${item.details.complements.length > 0 ? `Complementos:
${item.details.complements.map((complement) => complement.itens.map((item) => `${item.quantity}x - ${item.name} (R$${(Number(item.quantity) * Number(item.value)).toFixed(2)})`).join('\n')).join('\n')}\n\n` : ''}`
          })
          .join('')}
________________________________

*Encomenda:* R$${cart.total?.toFixed(2)}
*Entrega:* ${cart.taxDelivery && cart.taxDelivery > 0 && typeof cart.taxDelivery === 'number' ? `R$${cart.taxDelivery?.toFixed(2)}` : "Grátis"}
${coupomValue > 0 ? `*Cupom:* R$${coupomValue.toFixed(2)}` : ''}
${taxValue === 0
          ? ""
          : taxValue > 0
            ? `*Taxa de Serviço:* R$${taxValue.toFixed(2)}`
            : `*Desconto:* R$${-taxValue.toFixed(2)}`
        }
${cashbackValue > 0 ? `*Cashback:* R$${cashbackValue.toFixed(2)}` : ''}
*Total:* R$${finalPrice.toFixed(2)}

*Pagamentos em:* ${cart.formsPayment.map((pay) => pay.label).join(', ')}
${cart.formsPayment.some((pay) => pay.label !== 'Dinheiro') && isNaN(change)
          ? "" :
          change > 0 ? `*Troco para:* R$${change?.toFixed(2)}
*Troco:* R$${(change - finalPrice)?.toFixed(2)}`
            : "*Não precisa de troco*"}

*Endereço de Entrega do cliente:*
*Rua:* ${cart.address.street}
*Nº:* ${cart.address.number}
*Complemento:* ${cart.address.complement ? cart.address.complement : 'Não informado'}
*Bairro:* ${cart.address.neighborhood}
*Referência:* ${cart.address.reference ? cart.address.reference : 'Não informado'}
*Cidade:* ${cart.address.city}

*ROTA:*
https://www.google.com/maps/place/${fullAdress}
________________________________
*Tecnologia*
www.whatsmenu.com.br`
        : ''
    if (buttonRef.current) {
      let phoneMessage = message
      buttonRef.current.disabled = true

      const sendToWhatsApp = () => {
        if (buttonRef.current) {
          const { linkWhatsapp, whatsappOficial } =
            profileOptions ?? profile.options
          const isWindows = navigator.userAgent.includes('Windows')

          let sendMobile: boolean = false;
          if (linkWhatsapp === true || linkWhatsapp === undefined) {
            sendMobile = true;
          }

          if (
            (whatsappOficial || possibleMobile) &&
            (sendMobile && !isWindows) &&
            button.name !== 'motoboy'
          ) {
            window.open(
              `whatsapp://send?phone=${i18n.t('ddi')}${cart.client.whatsapp}&text=${encodeTextURL(
                cart.client.name,
                phoneMessage.replaceAll('[NOME]', cart.client.name)
              )}`
            )
            buttonRef.current.disabled = false
            return
          }

          if (
            (whatsappOficial || possibleMobile) &&
            (sendMobile && !isWindows) &&
            button.name === 'motoboy'
          ) {
            const message = encodeURIComponent(motoboyDefaultMessage)
            window.open(
              `whatsapp://send?phone=${i18n.t('ddi')}${currMotoboy.whatsapp.replace(/\D/g, '')}&text=${message}`
            )
            buttonRef.current.disabled = false
            return
          }

          if (!('isElectron' in window) && button.name !== 'motoboy') {
            window.open(
              `https://web.whatsapp.com/send?phone=${i18n.t('ddi')}${cart.client.whatsapp}&text=${encodeTextURL(cart.client.name, phoneMessage)}`
            )
            buttonRef.current.disabled = false
            return
          }

          if (
            currMotoboy &&
            !('isElectron' in window) &&
            button.name === 'motoboy'
          ) {
            const message = encodeURIComponent(motoboyDefaultMessage)
            window.open(
              `https://web.whatsapp.com/send?phone=${i18n.t('ddi')}${currMotoboy.whatsapp.replace(/\D/g, '')}&text=${message}`
            )
            buttonRef.current.disabled = false
            return
          }

          if ('isElectron' in window) {
            const contact = cart.client?.controls?.whatsapp?.contactId
              ? cart.client?.controls?.whatsapp?.contactId.user
              : `${i18n.t('ddi')}${cart.client.whatsapp}`
            sendMessage(
              contact,
              phoneMessage.replaceAll('[NOME]', cart.client.name)
            )
            if (currMotoboy && cart.type !== 'T' && newStatus === 'transport') {
              sendMessage(
                `${i18n.t('ddi')}${currMotoboy.whatsapp.replace(/\D/g, '')}`,
                motoboyDefaultMessage
              )
            }
            onMessageSend((client: any) => {
              cart.client = client
              setCart(cart)
            })
            return
          }
        }
      }

      if (newStatus || newStatus === null) {
        const waitMillis = localStorage.getItem('waitMillis')
          ? Number(localStorage.getItem('waitMillis'))
          : 5000
        const oldStatus = cart.status

        if (newStatus !== null) {
          switch (newStatus) {
            case 'production':
              phoneMessage =
                (profileOptions ?? profile.options).placeholders
                  .statusProduction ??
                (cart.type === 'P'
                  ? cart.defaultStatusProductionPackage
                  : cart.defaultStatusProductionMessage)
              setMessage(
                (profileOptions ?? profile.options).placeholders
                  .statusProduction ??
                (cart.type === 'P'
                  ? cart.defaultStatusProductionPackage
                  : cart.defaultStatusProductionMessage)
              )
              break
            case 'transport':
              if (cart.address) {
                phoneMessage =
                  (profileOptions ?? profile.options).placeholders.statusSend ||
                  cart.defaultStatusTransportMessage
                setMessage(
                  (profileOptions ?? profile.options).placeholders.statusSend ||
                  cart.defaultStatusTransportMessage
                )
              } else {
                phoneMessage =
                  (profileOptions ?? profile.options).placeholders
                    .statusToRemove || cart.defaultStatusToRemoveMessage
                setMessage(
                  (profileOptions ?? profile.options).placeholders
                    .statusToRemove || cart.defaultStatusToRemoveMessage
                )
              }
              break
            case 'motoboy':
              if (!('isElectron' in window)) {
                phoneMessage = motoboyDefaultMessage
                setMessage(motoboyDefaultMessage)
              } else {
                phoneMessage = motoboyDefaultMessage
                setMessage(motoboyDefaultMessage)
              }
              break
            case 'canceled':
              if (cart.origin === 'ifood') {
                setShowReasons(true)
              }
              phoneMessage = cart.defaultCanceledMessage
              setMessage(cart.defaultCanceledMessage)
              break
            default:
              phoneMessage = (profileOptions ?? profile.options).placeholders
                .clientText
              setMessage(
                (profileOptions ?? profile.options).placeholders.clientText
              )
              break
          }
        }

        if (
          cart.print ||
          profile.options.print.app ||
          cart.type === 'P' ||
          cart.origin === 'ifood'
        ) {
          if (
            door &&
            DateTime.local().toMillis() - lastRequestDate > waitMillis
          ) {
            try {
              setLoading && setLoading(true)
              if (newStatus !== 'motoboy') {
                await cart.updateStatus(newStatus, session)
              }
              if (cart.type === 'P') {
                setRequestsToPackage({ ...requestsToPackage })
              }
              if (cart.type !== 'T' && newStatus) {
                if (oldStatus !== newStatus && oldStatus !== 'canceled') {
                  sendToWhatsApp()
                }
              }
            } catch (error) {
              throw error
            } finally {
              buttonRef.current.disabled = false
              setLoading && setLoading(false)
              setCarts([...carts])
            }
          } else {
            handleShowToast({
              show: true,
              title: 'Aguarde',
              content: 'Um pedido esta sendo impresso, aguarde a finalização',
            })
          }
        }
      } else {
        if (cart.type !== 'T' && newStatus !== null) {
          sendToWhatsApp()
        }
      }
    }
  }

  return (
    <>
      <RefuseOrderModal
        show={showReasons}
        orderId={cart.orderId}
        reasons={reasons}
        onHide={() => setShowReasons(false)}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onCancelOrder={(data) => {
          cart.status = 'canceled'
          setCart(cart)
          setShowReasons(false)
          setNewPlacedOrders((prev) => ({
            ...prev,
            orders: prev.orders.filter(
              (order: any) => order.orderId !== data.order.orderId
            ),
          }))
        }}
      />

      <Button ref={buttonRef} {...button.props} onClick={handleChangeStatus}>
        {loading ? (
          <>
            <Spinner
              size="sm"
              animation="border"
              variant="light"
              className="d-inline-block ms-1"
            />
            <span className="d-inline-block ms-1">
              {i18n.t('please_wait_n')}
            </span>
          </>
        ) : button.name === 'motoboy' ? (
          <GiFullMotorcycleHelmet size={25} />
        ) : (
          button.name
        )}
      </Button>
    </>
  )
}
